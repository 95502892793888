@import "../variables.scss";

.ah {
  &-card {
    height: calc(100% - 45px);
    width: 100%;
    border-radius: 15px;
    box-shadow: $card-box-shadow;
    overflow: auto;
    background: $white;
    padding: 5px;
    @media (max-width: $screen-xs-max) {
      height: calc(100% - 40px);
    }
  }

  &-small-card {
    @media (max-width: $screen-xs-max) {
      height: auto;
      width: auto;
    }
  }

  &-small-card ~ footer {
    @media (min-width: $screen-xs-max) {
      position: absolute;
      width: calc(100% - 48px);
      bottom: 0;
    }
  }
}
