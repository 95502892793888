@import "../variables.scss";

$height-header: 125px;
$height-footer: 80px;
$height-body: $height-header + $height-footer;
$callapse-width: 300px;
.ah {
  &-question-logo {
    width: 180px;
    max-width: 100%;
  }
  &-cardheader {
    background-color: $white;
    height: $height-header;
    border-bottom-color: $white;
  }
  &-cardbody {
    height: calc(100% - #{$height-body});
    overflow: visible;
    @media (max-width: $screen-xs-min) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &-cardfooter {
    background-color: $white;
    height: $height-footer;
  }
  &-progress {
    height: 3px;
    position: relative;
    max-width: 120%;
    box-shadow: -7px 20px 57px -11px rgba(145, 139, 145, 0.04);
  }
  &-tooltip {
    padding: 0.5rem 0;
    background: $primary-color;
    position: relative;
    width: 52px;
    text-align: center;
    transition: left 0.5s ease-in-out;
    border-radius: 2.5px;
    color: $white;
    top: -8px;
    max-width: 100%;
    transform: translateX(-27px);
    &::before {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $primary-color;
      top: 100%;
      left: 19px;
    }
  }
  &-50 {
    height: 50vh !important;
  }
  &-60 {
    height: 60vh !important;
  }
  &-70 {
    height: 70vh !important;
  }
  &-collapse {
    position: fixed;
    top: 24px;
    right: 24px;
    background-color: $white;
    z-index: 21;
    height: calc(100% - 69px);
    width: $callapse-width;
    box-shadow: rgba(33, 35, 38, 0.07) -5px 0px 10px 0px;
    border-radius: 0px 15px 15px 0px;
    &-duration {
      color: $secondary-color;
    }
  }
  &-button-text {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    width: 100%;
    text-align: left;
    color: $dark-text-color;
    &:hover {
      color: $dark-text-color;
      background-color: rgba($separator-color, 0.5);
    }
    &:active {
      background-color: rgba($color: $primary-color, $alpha: 0.2) !important;
      color:  $primary-color !important;
    }
    &:focus {
      box-shadow: none !important;
    }
    &.active {
      color:  $primary-color !important;
      background-color: rgba($color: $primary-color, $alpha: 0.2) !important;
      .ah-collapse-duration {
        color: $primary-color
      }
    }
  }
}
.ah-container-qcm {
  margin-left: 30px;
}
.ah-p2-standard {
  margin-left: 10px;
}
.overflow-auto{
  overflow: auto;
}

.ah-question-logo{
  width: 100px;
}

.navbar-light .navbar-toggler-icon {
  width: 20px;
}
.navbar-light .collabse-visible .navbar-toggler-icon{
  width: 20px;
}
.navbar-light .navbar-toggler {
  padding: 0px;
  border: none;
  border-radius: 0px;

  &:focus {
    outline: none;
  }
}
.navbar-light .collabse-visible.navbar-toggler {
  position: relative;
  right: 10px;
  top: 5px;
  padding: 0px;
  border: none;
  border-radius: 0px;
  &:focus {
    outline: none;
  }
}

.collapsing {
  width: 0;
  transition: width 0.35s ease;
}
.nav-scroll {
  height: calc(100% - 40px);
  flex-wrap: nowrap;
}