@import "../variables.scss";

.ah {
  &-title-instructions,
  &-text-instructions u {
    color: $primary-color;
  }

  &-title-instructions,
  &-text-instructions {
    margin-top: 18px;
    font-size: 1.2rem;
  }
}
