@import "../variables.scss";
$height-header: 118px;
.ah {
  &-cardheader-valid {
    background-color: $white;
    box-shadow: -7px 20px 57px -11px rgba(145, 139, 145, 0.04);
    height: $height-header;
    border-bottom-color: $primary-color;
    border-block-end-width: medium;
  }
  &-ramarq-textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 2px solid;
    width: 100%;
    height: 200px;
  }
}
