@import "../variables.scss";
.Dropzone {
	min-height: 400px;
	min-width: 350px;
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
}
.Dropzone-logo {
	min-height: 220px;
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
}
.DropZone_center {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.Dropzone_container {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
	padding: 20px;
	border-radius: 2px;
	border: 2px dashed rgb(187, 186, 186);
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
}
.ah-glyph {
	font-size: 50px;
	text-align: center !important;
	display: block;
	line-height: 1.2em;
	color: #fe4a56;
}

i.ah-glyph p {
	color: black;
}

.ah-cv-display {
	width: 100%;
	height: 563px;
	margin: 10px 0;
}

.popover {
	border-radius: $border-radius;
	background-color: $white;
	border-color: $separator-color;
	&_cv {
		min-width: 400px !important;
	}
}

.popover-header {
	background-color: transparent;
	border-bottom: initial;
}
