@import "../variables.scss";

.ah {
  &-arrow-Style {
    width: 0;
    height: 0;
    border-top: 130px solid transparent;
    border-left: 180px solid $primary-color;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  &-row {
    align-items: center;
    height: 100%;
  }
  &-row &-homeh3 {
    text-align: center;
  }
  &-btn-SuivantHome {
    margin-top: 25px;
  }
  &-logo-home {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 180px;
    max-width: 100%;
    @media (max-width: $screen-xs-min) {
      position: relative;
    }
  }
  &-home-validation {
    @media (max-width: $screen-xs-min) {
      padding: 1rem !important;
    }
    .ah-select > span + div {
      border: none;
      box-shadow: none;
    }
  }

	&-logo-home-top {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 180px;
		max-width: 100%;
		@media (max-width: $screen-xs-min) {
			position: relative;
		}
	}
}
