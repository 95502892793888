@import "../variables.scss";

.ah {
  &-footer {
    padding: 5px 0;
    font-size: 10px;
    color: $text-color-secondary;
  }
  &-logo {
    width: 100px;
    max-width: 100%;
  }
}
