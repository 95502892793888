@import "../variables.scss";

.ah {
  &-error-page {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
  &-logo-error {
    width: 350px;
    max-width: 100%;
  }
}
