.btn-multiple-state {
    position: relative;
    transition: opacity 500ms;
  
    .spinner,
    .icon {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      visibility: hidden;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 500ms;
      color: $button-text-color;
    }
  
    .icon i {
      vertical-align: text-bottom;
      font-size: 18px;
    }
  
    .label {
      transition: opacity 500ms;
    }
  
    &.show-spinner {
      .label {
        opacity: 0;
      }
  
      .spinner {
        opacity: 1;
        visibility: visible;
      }
    }
  
    &.show-success {
      .label {
        opacity: 0;
      }
  
      .icon.success {
        opacity: 1;
        visibility: visible;
      }
    }
  
    &.show-fail {
      .label {
        opacity: 0;
      }
  
      .icon.fail {
        opacity: 1;
        visibility: visible;
      }
    }
  
    &.btn-primary:disabled {
      opacity: 1;
      background: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
   
    &.btn-secondary:disabled {
      opacity: 1;
      border-color: darken($white, 10%);
    }
  }
  
  .spinner > span {
    width: 6px;
    height: 6px;
    background-color: $white;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
  
    40% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white;
   
    &:hover {
      color: $white;
      background-color: darken($primary-color, 8%);
      border-color: darken($primary-color, 8%);
    }
    &:active {
      color: $white;
      background: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: darken($primary-color, 12%);
    border-color: darken($primary-color, 12%);
    color: $white;
    border-color: aliceblue;
  }