@import "../variables.scss";

.ah {
  &-Editor {
    position: relative;
    width: 100%;
    height: calc(100% - 38px);
  }
  &-selectStyle {
    width: 130px;
    text-align: left;
    text-transform: capitalize;
    > div {
      z-index: 20;
      margin: 0;
    }
  }
  &-editor-wrapper {
    background-color: $white;
    width: max-content;
  }

  &-editor-align {
    justify-content: flex-end;
  }

  &-compagne-title {
    height: 71px;
    overflow: hidden;
  }

  &-scroll-down {
    margin: 25px 30px;
    color: $primary-color;
    // border-radius: 50px;
    // border: 3px solid $primary-color;
    padding: 10px;
    width: 70px !important;
    height: 70px;
    left: 0;
    text-align: center;
  }

  &-bounce {
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
  }
  
  &-text-error {
    padding: 0 15px;
  }

  &-testResults {
    &-tc-item {
      border-top: 0;
      border-right: 0;
      border-left: 0;
    } 

    &-tc-badge {
      &-success {
        background-color: $success-color;
      }
      &-dark {
        background-color: $badge-color-dark;
      }
      &-info {
        background-color: $info-color;
      }
    }
  
    &-tc-header {
      color: $text-color-secondary;
    }
  }
}
.css-vj8t7z {
  border-color: transparent;
  background-color: transparent !important;
}

.css-2o5izw {
  background-color: transparent !important;
}
.css-xp4uvy {
  border-bottom: 1px dashed hsl(0, 0%, 80%) !important;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}

.button-section {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: $secondary-light-1-color; 
  border-bottom: 1px solid $secondary-light-2-color;
  border-radius: 5px 5px 0 0;
  text-align: center;
}


