@import "../variables.scss";

.ah {
  &-fade {
    display: inline-block;
    margin-left: 0.5rem;
    transform: scale(1);
  }
  &-fade-enter {
    opacity: 0.01;
    transform: translateX(200%) scale(0.75);
  }
  &-fade-enter-active {
    opacity: 1;
    transform: translateX(0%) scale(1);
    transition: all 300ms ease-out;
  }
  &-fade-exit {
    opacity: 1;
    transform: scale(1);
  }
  &-fade-exit-active {
    opacity: 0;
    transform: scale(4);
    transition: all 300ms ease-in;
  }

  &-transition {
    overflow-x: hidden;
  }
}
