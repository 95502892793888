@import "../variables.scss";

.ah {
  /**** Button ****/
  &-button {
    background: $primary-color;
    border-color: $primary-color;
    width: auto;
    border: 1px solid transparent;
    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($primary-color, 4%);
      background: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
    &:hover {
      background: darken($primary-color, 5%);
      border-color: darken($primary-color, 5%);
      &:active {
        background: darken($primary-color, 3%);
      }
    }
    &:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      color: $white;
      background-color: darken($primary-color, 5%);
      border-color: darken($primary-color, 5%);
      &:focus,
      .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem $primary-color;
        background: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
      }   
    }
    &.disabled {
      background: lighten($primary-color, 10%) !important;
      border-color: lighten($primary-color, 10%) !important;
    }
    &_run-test {
      position: absolute;
      bottom: -20px;
      right: -40px;
      border: none;
      background: white;
      padding: 0;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      z-index: 20;
      justify-content: center;
      &:hover {
        border-color: $white;
        background-color: $white;
      }
      &:active {
        border-color: darken($color: $white, $amount: 10%) !important;
        background-color: darken($color: $white, $amount: 10%) !important;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.3);
      }
    }
  }
  /*** Checkbox ***/

  &-checkbox label::before {
    border-radius: 2px;
  }
  &-radiobutton label::before {
    border-radius: 7px;
  }
  &-checkbox input[type="checkbox"],
  &-radiobutton input[type="radio"] {
    opacity: 0;
    + label::after {
      content: none;
    }
    &:checked + label::after {
      content: "";
    }
  }
  &-checkbox,
  &-radiobutton {
    padding-left: 40px;
    position: relative;
    label {
      position: relative;
      display: inline-block;
      padding-left: 40px;
      &::before {
        height: 16px;
        width: 16px;
        border: 1.3px solid;
        left: 0px;
        top: 3px;
      }
      &::after {
        height: 10px;
        width: 17px;
        border-left: 4px solid;
        border-bottom: 4px solid;
        color: $primary-color;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        left: 4px;
        top: 2px;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
      }
    }
  }

  /*** Input ***/

  &-lbl-input {
    padding-top: 7px;
  }
  &-input {
    margin-top: 40px;
  }
  &-select {
    > div {
      border: 0px;
      outline: 0px;
    }
  }
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.ah-input-border {
  border: 0px;
  background-color: $white;
  &:focus {
    background-color: $white;
  }
}
