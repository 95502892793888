@import "../variables.scss";

.ah-modal {
  &-header {
    span {
      color: $text-color-dark;
    }
    background: #024d65;
    color: $background;
    font-weight: bold;
  }

  &-body {
    &-container {
      font-size: 1.2em;
    }
    &-labels {
      font-weight: bold;
    }
  }
}
.ah-popover { 
  max-width: 600px !important;
  width: 500px;
  box-shadow: 0 7px 40px 2px hsla(210, 1%, 58%, 0.3);
  margin-bottom: 75px;

  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  border-radius: 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

  &-body {
    max-height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &_list { 
    max-height: 250px;
  }
}

video {
  object-fit: contain !important;
}

.resultSuccess {
  color: #2ecc71;
}
.resultFailed {
  color: #e74c3c;
}
