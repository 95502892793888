@import "../variables.scss";
.ah {
  &-success-page {
    top: 35%;
    position: relative;
    transform: translateY(-30%);
  }
  &-logo-success {
    width: 180px;
  }
  &-icon-success {
    font-size: 55px;
    color: $primary-color;
  }
  &-success-text1 {
    color: $text-color-secondary;
  }
  &-success-text2 {
    color: $text-color-primary;
  }
}
