@import "./variables.scss";
html,
body,
#root {
  height: 100%;
}
body {
  font-family: "Lato", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  background: $background;
  display: block;
}

.ah-text-primary,
.danger-time {
  color: $primary-color;
}
.ah {
  &-main-container {
    padding: 24px;
    padding-bottom: 0;
  }
  &-primary-color {
    color: $primary-color;
  }
  &-p-standard {
    display: inline-block;
    font-size: 2rem;
  }
  &-p2-standard {
    font-size: 1.5rem;
  }
}

@media (max-width: $screen-xs-max) {
  .ah-main-container {
    padding: 10px;
    padding-bottom: 0;
  }
}
.ah-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
}
.ah-loading {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
}
.ah-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $primary-color;
  border-radius: 50%;
  animation: ah-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-color transparent transparent transparent;
}
.ah-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.ah-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.ah-loading div:nth-child(3) {
  animation-delay: -0.15s;
}
.ps__rail-y {
  z-index: 9999;
}
@keyframes ah-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// .scroll-downs {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   margin: auto;

//   width: 34px;
//   height: 55px;
// }
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 30px;
  border: 2px solid $primary-color;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: $primary-color;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	display: block;
	background-image: none;
}
.text-danger, .text-label{
	font-size: 0.93em; 
}
.my-scrollbar{
  overflow-y: auto;
  overflow-x: hidden;
  height: 55vh;
  margin-bottom: 2vh;
}