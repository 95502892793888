@import "../variables.scss";

.css-10nd86i.react-select {
	outline: initial;
	box-shadow: none;
}

.react-select .react-select__value-container {
	padding: 0.45rem 0.75rem 0.4rem 0.75rem;
}
.react-select .react-select__control {
	border-radius: $border-radius;
	border: 1px solid $separator-color;
	background: $white;
	outline: initial;
	box-shadow: none;
}
.react-select .react-select__menu {
	border-radius: $border-radius;
	z-index: 20;
	box-shadow: initial;
	border: 1px solid $primary-color;
	border-top: initial;
	margin-top: -1px;
	background-color: $white;
	border-width: 1px;
}

.react-select .react-select__dropdown-indicator {
	color: $separator-color;
  }
  
  .react-select .react-select__menu-list {
	padding-bottom: 0;
	padding-top: 0;
  }
  
  .react-select .react-select__single-value,
  .react-select .react-select__multi-value__label {
	color: $text-color-dark;
  }
  
  .react-select .react-select__dropdown-indicator,
  .react-select
	.react-select__control--is-focused
	.react-select__dropdown-indicator,
  .react-select .react-select__clear-indicator,
  .react-select
	.react-select__control--is-focused
	.react-select__clear-indicator {
	outline: initial;
	box-shadow: initial;
  
	&:active,
	&:focus,
	&:hover {
	  color: $primary-color;
	}
  } 
  
  .react-select__indicator-separator {
	display: none;
  }
  
  .react-select__dropdown-indicator svg {
	width: 15px;
	height: 15px;
  }
  
  .react-select .react-select__option {
	background: $white;
	color: $text-color-dark;
  
	&:hover,
	&:active {
	  background: $primary-color;
	  color: $button-text-color;
	}
  }
  
  .react-select .react-select__option--is-selected {
	background: $primary-color;
	color: $button-text-color;
  }
  
  .react-select .react-select__control.react-select__control--is-focused {
	border-color: rgba($primary-color, 0.6);
  }
  
  .react-select__multi-value__remove:hover,
  .react-select__multi-value__remove:active {
	background: transparent;
	color: $primary-color;
  }
  
 
  .react-select__single-value {
	bottom: 0;
	top: 50%;
	padding-top: 2px;
  }

  .form-control:focus {
	border-color: rgba($primary-color, 0.6);
  }
