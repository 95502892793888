@import "../variables.scss";

.ah {
  &-content {
    margin-top: 30px;
    @media (max-width: $screen-xs-min) {
      margin-top: 0px;
    }
  }
  &-textarea {
    margin-top: 40px;
    position: relative;
  }
  &-textarea__count,
  &-extarea__count--error {
    position: absolute;
    right: 6px;
    bottom: 4px;
    opacity: 0.5;
    font-size: small;
  }
  &-textArea {
    display: block;
    resize: none;
    border-radius: 0;
  }
}
