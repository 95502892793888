@import "../variables.scss";

.ah {
  &-s2-txt1 {
    font-size: 17px;
    line-height: 1;
    color: $primary-color;
    text-transform: uppercase;
  }

  &-l2-txt1 {
    font-size: 40px;
    line-height: 1;
    color:$black;
  }

  &-size2 {
    width: 100px;
    height: 100px;
  }

  &-sep {
    font-size: 20px;
    font-weight: bold;
    margin-top: -15px;
  }
}
