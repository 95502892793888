@import "../variables.scss";

.react-datepicker-popper {
	z-index: 20;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
}
.react-datepicker {
	border: 1px solid $separator-color;
	border-radius: $border-radius;
	font-family: "Nunito", sans-serif;
}

.react-datepicker__header {
	background-color: $white;
	border-bottom: initial;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	width: 35px;
	height: 35px;
	line-height: 2.3rem;
	border-radius: 0 !important;
	margin: 0;
	outline: initial !important;
}
.react-datepicker__day:hover {
	background: $separator-color;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
	background: $primary-color;
}

.react-datepicker__day--today {
	font-weight: 400;
	color: $button-text-color !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
	border-bottom-color: $primary-color;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before {
	border-bottom-color: $separator-color;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
	color: $dark-text-color;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: $dark-text-color;
}

.react-datepicker__input-container input {
	color: $dark-text-color;
}

.react-datepicker__time-container {
	border-left: 1px solid $separator-color;
	width: 71px;
}

.react-datepicker__time-container .react-datepicker__time {
	background-color: $white;
	color: $dark-text-color;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
	color: $white;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: $primary-color;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: $separator-color;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	background: $background-color;
}

.react-datepicker__triangle {
	left: 30px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
	background: $primary-color;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
	background: $separator-color;
}

.react-datepicker.embedded {
	border: initial;
	width: 100%;

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 14.28%;
	}

	.react-datepicker__month-container {
		width: 100%;
	}
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list {
	padding-left: 0;
	padding-right: 30px;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background: $primary-color;
}
