$screen-xs-min: 575px;

$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

/* Colors */
$white: #fff !default;
$primary-color: #fe4a56 !default;
$error-color: #e65100;
$black: #0000;
$background: #f2f2f2;
$text-color-secondary: #515553;
$text-color-primary: #9da19f;
$separator-color: #d7d7d7;
$button-text-color: #d0d0d0;
$dark-text-color: #212121;
$background-color: #f8f8f8;
$secondary-color: #10c7d7;
$text-color-dark:#212121;

$success-color: #4BB543;
$info-color: #17a2b8;
$badge-color-dark: #9BAAB3;
$secondary-light-1-color: #F7F7F7;
$secondary-light-2-color: #EBEBEB;

/*Box-shadow*/
$card-box-shadow: 0px 0px 25px 0px rgba(194, 194, 194, 0.5);

/*border-raduis*/
$border-radius: 0.1rem;
