@import "../variables.scss";

.ah {
  &-nvlogo {
    margin-top: 70px;
    width: 400px;
    max-width: 100%;
    margin-bottom: 20px;
  }

  &-exclamation {
    margin: 25px 30px;
    color: $primary-color;
    border-radius: 50px;
    border: 3px solid $primary-color;
    padding: 10px;
    width: 100px !important;
    height: 100px;
    left: 0;
    text-align: center;
  }

  &-terminal {
    width: 30px !important;
    height: 30px;
    text-align: center;
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-paragraph {
    margin-top: 35px;
    text-align: center;
    font-size: 1.7em;
  }

  &-contact-us {
    font-weight: 50;
    color: $text-color-secondary;
  }
}
